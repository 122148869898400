let currentAudio = null;

soundVocabulary = function (parent) {
  element = ''
  if (parent == undefined) {
    element = '.sound-icon'
  } else {
    element = parent + ' .sound-icon'
  }
  $(element).click(function () {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0; // Reset to the beginning
    }

    keyword = $(this).attr('id').match(/[a-zA-Z]+((\S)?[a-zA-Z]+)?/)?.[0]
    var source = "https://translate.google.com.vn/translate_tts?ie=UTF-8&q=" + keyword + "&tl=en&client=tw-ob"
    currentAudio = new Audio(source);
    currentAudio.play();
  });
}
