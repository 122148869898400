showPopupTranslate = function (element) {
  $(element).click(function (e) {
    $(this).css('background-color', 'yellow')
    $(this).css('color', 'black')
    // $(this).css('padding', '1px')
    $(this).css('border-radius', '5px')
    e.stopPropagation();
    $('div#pop-up-translate img.sound-icon').addClass('d-none')
    $('.translate-word-types p').remove()
    $('div#pop-up-translate span.translate-text').text('...')
    $('div#pop-up-translate p.transliteration').text('')
    $('div#pop-up-translate p.suggest').text('')
    text = $(this).text();
    voice_text = text.toLowerCase().replace(/[^a-z0-9\s]/gi, '')
    $.ajax({
      type: "GET",
      url: api_url + '/api/v1/dictionary/google_translate?from_lang=en&keyword=' + text.match(/[a-zA-Z]+((\S)?[a-zA-Z]+)?/)?.[0],
      headers: { 'Access-Control-Allow-Origin': '*' },
      success: function (data, status, xhr) {
        translate_text = data['translate']
        transliteration = data['transliteration']
        suggest = data['suggest']
        word_types = data['word_types']
        if (word_types !== undefined) {
          $.each(word_types, function (i, word_type) {
            $.each(word_type, function (key, value) {
              $('div.translate-word-types').append("<p class='type-key'>" + key + "</p>")
              $('div.translate-word-types').append("<p class='type-value'>" + value + "</p>")
            });
          });
        }
        $('div#pop-up-translate span.translate-text').text(translate_text)
        $('div#pop-up-translate img.sound-icon').removeClass('d-none')
        $('div#pop-up-translate img.sound-icon').attr('id', voice_text)
        if (transliteration != null) {
          $('div#pop-up-translate p.transliteration').text("/" + transliteration + "/")
        }
        if (suggest != null) {
          $('div#pop-up-translate p.suggest').html('Did you mean <span>' + suggest + '</span>?')
        }
      }
    });
    $.ajax({
      type: "POST",
      url: '/api/v1/vocabulary?keyword=' + text,
    });
    $('div#pop-up').show();
    var moveLeft = 20;
    var moveDown = 10;
    html_width = parseInt($('html').css('width'), 10)
    popup_width = 130
    if (popup_width + e.pageX >= html_width) {
      $("div#pop-up").css('top', e.pageY + moveDown).css('left', e.pageX - popup_width);
    } else {
      $("div#pop-up").css('top', e.pageY + moveDown).css('left', e.pageX + moveLeft);
    }
  });
}

convertVocabulary = function(element, new_words, learned_words, show_popup_element){
  const under_scrore_pattern = /^_+.$/;

  $(element).html(function () {
    paragraphs = $(this).text().split(/\r?\n/)
    result = ''
    $.each(paragraphs, function( i, paragraph) {
      arr_paragraph = paragraph.split(" ")
      $.each(arr_paragraph, function( i, val) {
        check_val = val.toLowerCase().match(/[a-zA-Z]+((\S)?[a-zA-Z]+)?/)?.[0]
        if(new_words.includes(check_val)){
          result += " <span class='new_word'>" + val + "</span>"
        }else if (learned_words.includes(check_val)){
          result += " <span class='old_word'>" + val + "</span>"
        }else{
          if (under_scrore_pattern.test(val)) {
            result += " <b>" + val + "</b>"
          } else {
            result += " <span class='vocabulary'>" + val + "</span>"
          }
        }
      });
      result += "<br>";
    });
    return result;
  });
  showPopupTranslate(show_popup_element)
}

$('html').click(function(){
  $('div#pop-up').hide();
  $('.translate-word-types p').remove()
  $('div#pop-up-translate span.translate-text').text('...')
  $('div#pop-up-translate p.transliteration').text('')
  $('div#pop-up-translate p.suggest').text('')
});
